/* You can add global styles to this file, and also import other style files */

.cart-item{
    font-size: medium;
}


.product-btn{
    font-size: medium;
    background-color: #1B2437;
    border-color: #1B2437;
}

.product-no-btn{
    font-size: medium;
    background-color: darkgrey;
    border-color: darkgrey;
    margin-right: 1px;
}

.no-producto{
    background-color: #1B2437;
    border-color: #1B2437;
    color: #fff;
}

.fa-star{
    color: darkgoldenrod;
    cursor: pointer;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.overlay{
    position:fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(88, 85, 85, 0.8);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s; /* Firefox < 16 */
     -ms-animation: fadein 0.5s; /* Internet Explorer */
      -o-animation: fadein 0.5s; /* Opera < 12.1 */
         animation: fadein 0.5s;
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

